'use client';

import { TAX_RATE } from '@/constants/general';
import Typography from '@mui/material/Typography';
import money from '@/utils/money';
import useAuth from '@/hooks/useAuth';
import LoginIcon from '@mui/icons-material/LoginOutlined';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface IPrice {
  price: number;
  discount: number;
  variant?: any;
  quantity?: number;
  showQuantity?: boolean;
  showFrom?: boolean;
}

export default function Price({
  price = 0,
  discount = 0,
  variant = 'h6',
  quantity = 1,
  showQuantity = false,
  showFrom = false,
}: IPrice) {
  const { setOpenLogin, userId, status } = useAuth();

  const taxPrice = price * TAX_RATE;

  if (status !== 'complete' || !price) {
    return <Skeleton variant='text' width={100} />;
  }

  if (!userId) {
    return (
      <div>
        <Button
          onClick={() => setOpenLogin(true)}
          variant='text'
          color='inherit'
          size='small'
          startIcon={<LoginIcon />}
          className='rounded'
          sx={{
            textTransform: 'none',
            whiteSpace: 'nowrap',
            p: 0.25,
          }}
        >
          Login to see price
        </Button>
      </div>
    );
  }

  const discountedPrice = taxPrice * (1 - discount / 100);

  return (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      {showQuantity && (
        <Typography
          variant={variant}
          component='span'
          noWrap
          sx={{ color: 'text.secondary', width: 20 }}
        >
          {quantity} x
        </Typography>
      )}

      {showFrom && (
        <Typography variant={variant} component='span' noWrap>
          From
        </Typography>
      )}

      <Typography variant={variant} component='span'>
        {money(discountedPrice)}
      </Typography>

      {!!discount && (
        <Typography
          variant={variant}
          component='span'
          sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
        >
          {money(taxPrice)}
        </Typography>
      )}

      {!!discount && (
        <Typography variant={variant} component='span' sx={{ color: 'error.main' }}>
          -{discount}%
        </Typography>
      )}
    </Stack>
  );
}
