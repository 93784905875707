export default function money(amount: number) {
  if (Number.isNaN(amount)) {
    amount = 0;
  }

  return `A${new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount)}`;
}
